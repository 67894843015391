<template>
  <div style="width:100%;height:100%;padding-left:50px">
    <div class="loading-div" v-show="searchLoading">
      <a-spin size="large" />
    </div>
    <div v-show="!searchLoading">
      <a-timeline class="see" v-if="pagination.total > 0">
        <a-timeline>
          <a-timeline-item class="tool_w_30" v-for="item in followFormArray" :key="item.name">
            <template #dot>
              <p class="see-drawer-dot-p">{{item.circulationType.label}}</p>
              <p class="see-drawer-dot-p1">{{item.circulationName}}</p>
            </template>
            <div class="see-drawer-content">
              <p>{{item.content}}</p>
              <p class="see-drawer-content-time">{{item.time}} </p>
              <p>{{remark}}</p>
            </div>
          </a-timeline-item>
        </a-timeline>
        <a-pagination show-quick-jumper v-model:current="pagination.current" :show-total="pagination.showTotal" :page-size-options="pagination.pageSizeOptions" :total="pagination.total" @change="onChange" />
      </a-timeline>
      <a-empty v-else />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { circulationPage } from '@/api/crmManagement/tool'
export default {
  props: {
    customerId: String
  },
  setup (props, context) {
    const state = reactive({
      customerId: props.customerId,
      searchLoading: false,
      followFormArray: [],
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: 1,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
      state.searchLoading = true
      circulationPage({
        customerId: state.customerId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.followFormArray = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.searchLoading = false
      })
    }
    const onChange = (e) => {
      state.pagination.current = e
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    return {
      ...toRefs(state),
      loadData,
      onChange
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../tool/index.less';
@import './index.less';
</style>
