import { postBodyRequest, getRequest } from '@/utils/axios'

export function circulationPage (params) {
  return postBodyRequest('/market/crm/circulation/page', params)
}

export function addTotal (params) {
  return getRequest('/market/crm/public/num', params)
}

// 更改为成交状态 客户合同新增
export function contractAdd (params) {
  return postBodyRequest('/market/crm/contract/add', params)
}

// 更改结算方式
export function settlementTypeChange (params) {
  return postBodyRequest('/market/crm/cooper/changeContract', params)
}

export function blackCustomer (params) {
  return postBodyRequest('/market/crm/cooper/blackCustomer', params)
}
export function transferCustom (params) {
  return postBodyRequest('/market/crm/private/batch-transfer', params)
}

